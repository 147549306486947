<template>
  <table class="tw-table-auto">
    <tr v-if="isRepresentative">
      <td class="tw-font-bold tw-pr-4">Vertegenwoordigd door</td>
      <td>
        {{ buyerProfile.display_name }}
      </td>
    </tr>
    <tr>
      <td class="tw-font-bold tw-pr-4">Adres</td>
      <td>
        {{ buyerProfile.address }}
      </td>
    </tr>
    <tr>
      <td class="tw-font-bold tw-pr-4">E-mailadres</td>
      <td>
        {{ buyerProfile.email || '-' }}
      </td>
    </tr>
    <tr>
      <td class="tw-font-bold tw-pr-4">Gsm-nummer</td>
      <td>
        {{ buyerProfile.mobile || '-' }}
      </td>
    </tr>
    <tr>
      <td class="tw-font-bold tw-pr-4">Telefoonnummer</td>
      <td>
        {{ buyerProfile.phone || '-' }}
      </td>
    </tr>
    <tr>
      <td class="tw-font-bold tw-pr-4">Geboortedatum</td>
      <td>
        {{ formatDateForLocale(buyerProfile.birth_date) }}
      </td>
    </tr>
    <tr>
      <td class="tw-font-bold tw-pr-4">Geboorteplaats</td>
      <td>
        {{ buyerProfile.birth_place }}
      </td>
    </tr>
  </table>
</template>

<script>
import { formatDateForLocale } from '@/utils/helpers'
export default {
  name: 'PropertyBidPersonBuyerProfile',
  props: {
    buyerProfile: {
      type: Object,
      required: true
    },
    isRepresentative: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    formatDateForLocale
  }
}
</script>

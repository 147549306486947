var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"name":"bidConfirmationStep2Form"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-my-8 tw-p-4 tw-border-l"},[_vm._v(" De transactie voor dossier "),_c('strong',[_vm._v(_vm._s(_vm.property.reference))]),_vm._v(" ontbreekt. Gelieve hieronder de intakegegevens in te vullen. Bij aanvaarding van het bod wordt een transactie aangemaakt waarbij de 'binnen' wordt ingevuld op basis van de ingegeven informatie. ")]),_c('TransactionIntakeInputs',{ref:"transactionIntakeInputs",attrs:{"property":_vm.property}}),_c('div',[_c('h2',{staticClass:"tw-mb-4"},[_vm._v("Betrokken kantoren en poules")]),_c('TransactionInvolvementData',{ref:"transactionInvolvements",attrs:{"context":"bidRegistration","property-id":_vm.property.id}})],1),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
          'fas tw-mr-2',
          isLoading
            ? 'fa-spinner-third fa-spin'
            : 'fa-save'
        ]}),_vm._v(" Bod aanvaard door eigenaar ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <BaseModal ref="modal" title="Biedingen" max-width="tw-max-w-7xl" @hide="onHide">
    <DataTable
      v-bind="bids"
      :loading="loading"
      :headers="headers"
      :infinite-scroll="true"
      class="tw--m-5 tw-shadow-none"
      @infiniteLoad="infiniteLoad"
    >
      <template #toolbar>
        <div class="tw-flex tw-items-center tw-gap-2">
          <button
            type="button"
            title="Nieuw bod"
            :disabled="newBidModalOpening"
            class="action tw-bg-success tw-mr-0"
            @click="showNewBidModal"
          >
            <i class="fas fa-plus" /> Nieuw bod
          </button>

          <Tooltip class="tw-inline-block">
            <i class="fas fa-info-circle" />
            <template #popper>
              Geef elk bod in - indien éénzelfde kandidaat meerdere biedingen doet, voeg deze dan apart in (niet wijzigen).
              Maw 2e bod = nieuwe bodregistratie.
            </template>
          </Tooltip>
        </div>
      </template>

      <template #item-info="{ item }">
        <Tooltip>
          <i class="fas fa-info-circle" />

          <template #popper>
            <p v-if="item.created_by">
              Aangemaakt door
              <b>{{ item.created_by.first_name }}</b>
              op
              <b>{{ item.created_on | date-day }}</b>
            </p>

            <p v-if="item.updated_by">
              Gewijzigd door
              <b>{{ item.updated_by.first_name }}</b>
              op
              <b>{{ item.updated_on | date-day }}</b>
            </p>
          </template>
        </Tooltip>
      </template>

      <template #item-property_bid_contacts="{ item }">
        <ul
          v-if="item.property_bid_contacts.length"
          class="tw-list-disc tw-ml-4"
        >
          <li
            v-for="object in item.property_bid_contacts"
            :key="object.id"
          >
            <router-link
              v-if="object.contact"
              :to="{ name: 'ContactDetails', params: { id: object.contact.id } }"
              target="_blank"
            >
              {{ object.contact.display_name }}
            </router-link>
          </li>
        </ul>
        <span v-else>-</span>
      </template>

      <template #item-property_bid_buyer_profiles="{ item }">
        <button
          v-if="item.property_bid_buyer_profiles.length"
          class="bt btn-default btn-xs tw-w-full"
          @click="showBuyerProfilesModal(item.property_bid_buyer_profiles)"
        >
          <i class="fa fa-eye tw-mr-1" /> Bekijken
        </button>
        <span v-else>-</span>
      </template>

      <template #item-match="{ item }">
        <router-link v-if="item.match" :to="{ name: 'LeadDetails',  params: { id: item.match } }" target="_blank">
          <i class="fa fa-link" />
        </router-link>
        <span v-else>-</span>
      </template>

      <template #item-amount="{ item }">
        {{ currency(item.amount) }}
      </template>

      <template #item-suspensive_condition="{ item }">
        {{ item.suspensive_condition ? item.suspensive_condition.name : '-' }}
      </template>

      <template #item-comment="{ item }">
        <div v-if="item.comment">{{ item.comment }}</div>
        <div v-if="item.loan_amount">
          Te ontlenen bedrag: {{ currency(item.loan_amount) }}
        </div>
        <div v-if="item.loan_approval_term">
          Goedkeuringstermijn: {{ item.loan_approval_term }} dagen
        </div>
      </template>

      <template #item-contact_confirmation_file="{ item }">
        <div class="tw-grid tw-place-items-center">
          <a
            v-if="item.contact_confirmation_file"
            :href="item.contact_confirmation_file.url"
            :title="item.contact_confirmation_file.filename"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
          >
            <i class="far fa-file-pdf tw-text-xl" />
          </a>
          <span v-else>-</span>
        </div>
      </template>

      <template #item-is_confirmed_in_writing="{ item }">
        <div class="tw-grid tw-place-items-center">
          <FormulateInput
            v-model="item.is_confirmed_in_writing"
            :id="`bid_${item.id}_is_confirmed_in_writing`"
            type="checkbox"
            name="is_confirmed_in_writing"
            outer-class="tw-my-0"
            @input="manuallyConfirmBid(item.id, $event)"
          />
        </div>
      </template>

      <template #item-is_accepted="{ item }">
        <div class="tw-grid tw-place-items-center">
          <FormulateInput
            v-model="item.is_accepted"
            :id="`bid_${item.id}_confirm`"
            type="checkbox"
            name="confirm"
            outer-class="tw-my-0"
            @input="showBidConfirmModal(item)"
            :disabled="hasAcceptedBid"
          />
        </div>
      </template>

      <template #item-actions="{ item }">
        <div class="tw-flex">
          <button
            v-if="!item.is_accepted"
            title="Bod verwijderen"
            type="button"
            class="action tw-bg-danger"
            @click="removeBid(item.id)"
          >
            <i class="far fa-trash-alt" />
          </button>
        </div>
      </template>
    </DataTable>

    <PropertyBidCreateModal
      ref="createNewBid"
      :lead-id="leadId"
      :property-id="propertyId"
      @bidCreated="loadTableData"
    />

    <PropertyBidConfirmEditModal
      ref="confirmEditBid"
      :property-id="propertyId"
      @bidAccepted="handleBidAccepted"
      @bidUpdated="loadTableData"
      @hideWithoutAcceptance="loadTableData"
    />
    <BaseModal
      ref="buyerProfilesModal"
      :title="buyerProfiles.length > 1 ? 'Kopersprofielen' : 'Kopersprofiel'"
    >
      <div
        v-for="buyerProfile in buyerProfiles"
        :key="buyerProfile.id"
        class="tw-p-4 tw-shadow-card tw-rounded tw-mb-4 last:tw-mb-0"
      >
        <h1 class="tw-mt-0 tw-text-base tw-font-bold">
          {{ getPersonOrCompanyBuyerProfile(buyerProfile).display_name }}
        </h1>
        <PropertyBidPersonBuyerProfile
          v-if="buyerProfile.person_buyer_profile"
          :buyer-profile="buyerProfile.person_buyer_profile"
        />
        <div v-else>
          <table class="tw-table-auto">
            <tr>
              <td class="tw-font-bold tw-pr-4">Type bedrijf</td>
              <td>
                {{ getPersonOrCompanyBuyerProfile(buyerProfile).type_display }}
              </td>
            </tr>
            <tr>
              <td class="tw-font-bold tw-pr-4">Btw-nummer</td>
              <td>
                {{ getPersonOrCompanyBuyerProfile(buyerProfile).vat || '-' }}
              </td>
            </tr>
            <tr>
              <td class="tw-font-bold tw-pr-4">Btw-plichtig</td>
              <td>
                {{ getPersonOrCompanyBuyerProfile(buyerProfile).is_vat_liable | yesno }}
              </td>
            </tr>
          </table>
          <div class="tw-mt-4 tw-border-t tw-pt-3">
            <PropertyBidPersonBuyerProfile
              :buyer-profile="buyerProfile.company_buyer_profile.person_buyer_profile"
              :is-representative="true"
            />
          </div>
        </div>
      </div>
    </BaseModal>
  </BaseModal>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { currency } from '@/utils/helpers'
import { questionModal } from '@/modalMessages'

import DataTable from '@/components/DataTable'
import PropertyBidCreateModal from '@/components/properties/PropertyBidCreateModal'
import PropertyBidConfirmEditModal from '@/components/properties/PropertyBidConfirmEditModal'
import PropertyBidPersonBuyerProfile from '@/components/properties/PropertyBidPersonBuyerProfile'

import { getPropertyBids, deletePropertyBid, updatePropertyBid } from '@/services/properties'

export default {
  name: 'PropertyBidsModal',
  components: {
    Tooltip,
    DataTable,
    PropertyBidCreateModal,
    PropertyBidConfirmEditModal,
    PropertyBidPersonBuyerProfile
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    leadId: {
      type: [Number, String],
      required: false
    },
    propertyConcept: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      bids: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      newBidModalOpening: false,
      confirmBidModalOpening: false,
      contacts: [],
      buyerProfiles: []
    }
  },
  computed: {
    headers () {
      return [
        { value: 'info', text: ' ' },
        { value: 'bid_date', text: 'Datum' },
        { value: 'property_bid_contacts', text: 'Kandidaat-koper' },
        // Onlly show the buyer profiles if the concept is DIY (6)
        ...this.propertyConcept === 6 ? [{ value: 'property_bid_buyer_profiles', text: 'Kopersprofiel' }] : [],
        { value: 'match', text: 'Lead' },
        { value: 'amount', text: 'Bod' },
        { value: 'valid_until_date', text: 'Geldig tot' },
        { value: 'suspensive_condition', text: 'Opschortende VW' },
        { value: 'comment', text: 'Opmerking' },
        { value: 'contact_confirmation_file', text: 'Bijlage' },
        { value: 'is_confirmed_in_writing', text: 'Schriftelijk bod' },
        { value: 'is_accepted', text: 'Aanvaard' },
        { value: 'actions', text: 'Actie' }
      ]
    },
    hasAcceptedBid () {
      return this.bids.results.some(bid => bid.is_accepted)
    }
  },
  methods: {
    currency,
    show (contacts = []) {
      this.contacts = contacts
      this.$refs.modal.show()
      return this.loadTableData() // The table has it's own loading state.
    },
    showBuyerProfilesModal (buyerProfiles) {
      this.buyerProfiles = buyerProfiles
      this.$refs.buyerProfilesModal.show()
    },
    getPersonOrCompanyBuyerProfile (buyerProfile) {
      if (buyerProfile.person_buyer_profile) {
        return buyerProfile.person_buyer_profile
      }
      return buyerProfile.company_buyer_profile
    },
    hide () {
      this.$refs.modal.hide()
    },
    onHide () {
      this.bids = {}
      this.loading = false
      this.firstLoad = true
      this.confirmBidModalLoading = false
    },
    handleBidAccepted () {
      this.$emit('bidAccepted')
      this.hide()
    },
    async showBidConfirmModal (bid) {
      this.confirmBidModalOpening = true
      const response = await this.$refs.confirmEditBid.show(bid)
      this.confirmBidModalOpening = false
      return response
    },

    async showNewBidModal () {
      this.newBidModalOpening = true
      const response = await this.$refs.createNewBid.show(this.contacts)
      this.newBidModalOpening = false
      return response
    },

    async loadTableData () {
      this.loading = true
      const payload = {
        propertyId: this.propertyId
      }
      const bids = await this.fetchPropertyBids(payload)
      this.bids = bids
      this.loading = false
      return bids
    },
    async fetchPropertyBids (payload) {
      const response = await getPropertyBids(payload)
      return response.data
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.bids.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const bids = await this.fetchPropertyBids({ url: next })
        const results = [...this.bids.results, ...bids.results]
        this.bids = { ...bids, results }
        $infinite.loaded()
        return bids
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async removeBid (bidId) {
      const result = await questionModal('Deze actie kan niet ongedaan gemaakt worden')
      if (!result.value) return

      const response = await deletePropertyBid(this.propertyId, bidId)
      this.loadTableData()
      return response
    },
    async manuallyConfirmBid (bidId, is_confirmed_in_writing) {
      const payload = { is_confirmed_in_writing }
      const response = await updatePropertyBid(this.propertyId, bidId, payload)
      return response
    }
  }
}
</script>
